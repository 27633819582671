// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import ResPadding from '~plugins/material-ui/components/ResPadding';
import RichText from '~plugins/prismic/components/RichText';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import type { PrismicPrivacyPolicy } from '~schema';

export type ClassKey = 'root' | 'title' | 'description';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicPrivacyPolicy>>,
};

const styles = (theme: Theme) => ({
  root: {
    marginTop: 65,
  },
  title: {
    color: theme.palette.secondary.main,
    marginTop: 0,
    marginBottom: theme.spacing(2),
  },
  description: {
    color: theme.palette.common.white,
    '& h2, & h3, & h4, & h5, & h6': {
      color: theme.palette.secondary.main,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    '& p:not(:last-child)': {
      paddingBottom: theme.spacing(1),
    },
    '& a': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
  },
});

const PrivacyPolicyPage = ({ classes, node }: Props) => {
  return (
    <Container component={ResPadding} vertical className={classes.root}>
      {node?.data?.page_title?.text ? (
        <Typography
          variant="h4"
          variantMapping={{ h4: 'h1' }}
          gutterBottom
          className={classes.title}
        >
          {node?.data?.page_title?.text}
        </Typography>
      ) : null}
      <RichText
        {...node?.data?.page_description}
        className={classes.description}
      />
    </Container>
  );
};

export default compose(
  withNodePage<PrismicPrivacyPolicy, *>({
    getNode: data => data?.prismicPrivacyPolicy,
  }),
  withStyles<ClassKey, *, Props>(styles),
)(PrivacyPolicyPage);

export const query = graphql`
  query PrivacyPolicyPageQuery($prismicId: ID) {
    prismicPrivacyPolicy(prismicId: { eq: $prismicId }) {
      id
      prismicId
      type
      lang
      first_publication_date
      last_publication_date
      slugs
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        title {
          text
        }
        page_title {
          text
        }
        page_description {
          text
          html
        }
      }
    }
  }
`;
